import { jwtDecode } from "jwt-decode"
import { JWTDecoded } from "../components/Dashboard/IDashboard"
import { keycloak } from "../providers/KeycloakAuthProvider"
import { ITableHeader, TableHeaders } from "../redux/store/IStore"

export const translateHeader = (key: TableHeaders) => {
    switch (key) {
        case TableHeaders.status: return "Stanje seje"
        case TableHeaders.name: return "Ime seje"
        case TableHeaders.createdByUser: return "Uporabnik"
        case TableHeaders.labels: return "Labele"
        case TableHeaders.sources: return "Viri"
        case TableHeaders.recordedMs: return "Dolžina seje"
        case TableHeaders.createdAt: return "Datum kreiranja"
        case TableHeaders.updatedAt: return "Zadnja sprememba"
        case TableHeaders.visibility: return "Vidnost"
    }
}

export const getInitialWidthForHeader = (key: TableHeaders) => {
    switch (key) {
        case TableHeaders.status: return 220
        case TableHeaders.name: return 220
        case TableHeaders.createdByUser: return 220
        case TableHeaders.labels: return 220
        case TableHeaders.sources: return 220
        case TableHeaders.recordedMs: return 220
        case TableHeaders.createdAt: return 220
        case TableHeaders.updatedAt: return 220
        case TableHeaders.visibility: return 220
    }
}

export const handleHeadersWrapper = (newHeaders: ITableHeader[]) => {
    if (!keycloak.token) {
        return;
      }
      const decodedToken: JWTDecoded = jwtDecode(keycloak.token)
      const userSettings = localStorage.getItem(decodedToken.sub);
      if (!userSettings) {
        localStorage.setItem(decodedToken.sub, JSON.stringify({tableHeaders: newHeaders}))
        return;
      }
      const parsedSettings = JSON.parse(userSettings)
      parsedSettings.tableHeaders = newHeaders;
      localStorage.setItem(decodedToken.sub, JSON.stringify(parsedSettings))
}