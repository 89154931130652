import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { cloneDeep } from 'lodash';
import { ITableHeader, SortDirection, TableHeaders } from '../../../redux/store/IStore';
import { handleHeadersWrapper } from '../../../shared/tableHeaderHelpers';
import { setTableHeader, setTableRows, setTableSort } from '../../../redux/features/app/app';
import './table_header_row.css'
import { enqueueSnackbar } from 'notistack';
import { AddCircleOutlineOutlined, RemoveCircleOutline } from '@mui/icons-material';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { SortDirectionType } from 'react-virtualized';

interface IColumnResizeArguments {
    dataKey: string;
    newWidth: number;
}

const TableHeaderRow = () => {
    const [anchorElOpenHeaders, setAnchorElOpenHeaders] = useState<null | HTMLElement>(null);

    const headerSelectOpen = Boolean(anchorElOpenHeaders);

    const dispatch = useAppDispatch();

    const tableHeaders = useAppSelector(store => store.app.tableHeaders)
    const tableSort = useAppSelector(store => store.app.tableSort)

    const handleHeadersCallback = (newHeaders: ITableHeader[]) => {
        handleHeadersWrapper(newHeaders);
        dispatch(setTableHeader(newHeaders));
    }

    const handleColumnResize = useCallback((args: IColumnResizeArguments) => {
        const {
            dataKey,
            newWidth
        } = args;

        let newFullWidth = 0
        const copiedHeaders = cloneDeep(tableHeaders);
        const index = copiedHeaders.findIndex(header => header.key === dataKey)
        if (index < 0) return tableHeaders;
        copiedHeaders[index].width = Math.max(newWidth, 220);
        newFullWidth += Math.max(newWidth, 220)
        handleHeadersCallback(copiedHeaders)
    }, [tableHeaders]);


    const hideColumn = (columnToHide: TableHeaders) => {
        if (tableHeaders.filter(header => !header.hidden).length === 1) {
            enqueueSnackbar("Prikazan mora biti vsaj en stolpec", { variant: 'info' })
            return;
        }

        const headersCopy = cloneDeep(tableHeaders)
        headersCopy.forEach(header => {
            if (columnToHide === header.key) header.hidden = true;
        })

        handleHeadersCallback(headersCopy);
    }

    const showColumn = (columnToShow: TableHeaders) => {
        const headersCopy = cloneDeep(tableHeaders)
        headersCopy.forEach(header => {
            if (columnToShow === header.key) header.hidden = false;
        })
        handleHeadersCallback(headersCopy);
    }

    const toggleHeaderDropdown = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!anchorElOpenHeaders) {
            const target = event.currentTarget;
            setAnchorElOpenHeaders(target.parentElement)
        } else {
            setAnchorElOpenHeaders(null);
        }
    }


    const handleColumnAdd = (event: React.MouseEvent<HTMLElement>, key: TableHeaders) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorElOpenHeaders(null);
        showColumn(key)
    }

    const headerClickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (anchorElOpenHeaders) {
            setAnchorElOpenHeaders(null);
        }
    };

    const handleDragStart = (index: number) => (event: React.DragEvent) => {
        event.dataTransfer.setData('columnIndex', index.toString());
    };

    const handleDrop = (index: number) => (event: React.DragEvent) => {
        const draggedIndex = event.dataTransfer.getData('columnIndex');
        const newColumns = cloneDeep(tableHeaders);
        const [movedColumn] = newColumns.splice(+draggedIndex, 1);
        newColumns.splice(index, 0, movedColumn);
        handleHeadersCallback(newColumns);
    };

    const handleSort = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, sortBy: TableHeaders, sortDirection: SortDirection) => {
        event.preventDefault();
        event.stopPropagation();

        if (sortBy === TableHeaders.labels || sortBy === TableHeaders.sources || sortBy === TableHeaders.status || sortBy === TableHeaders.visibility) {
            enqueueSnackbar("Stolpec ni na voljo za sortiranje", { variant: 'info' })
            return;
        }

        console.log({ sortBy, sortDirection })
        const copyHeaders = cloneDeep(tableHeaders);
        copyHeaders.forEach(header => {
            header.sortDirection = header.key === sortBy ? sortDirection : null
        })
        dispatch(setTableHeader(copyHeaders));
        dispatch(setTableSort({ sortBy, sortDirection }));
        //dispatch(setTableRows([]))
    }

    return (
        <tr>
            {tableHeaders.filter(tableHeader => !tableHeader.hidden).map((header, index) => {
                return (
                    <th
                        className="table_head_cell"
                        key={header.key}
                        style={{ width: header.width }}
                        draggable
                        onDragStart={handleDragStart(index)}
                        onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
                        onDrop={handleDrop(index)}
                    >
                        <div className='table_head_cell_container'>
                            <div
                                className='table_head_cell_label_container'
                                onClick={event => handleSort(event, header.key, header.sortDirection ? header.sortDirection === SortDirection.asc ? SortDirection.desc : SortDirection.asc : SortDirection.desc)}>
                                <p className='table_head_cell_label'>{header.label}</p>
                                {tableSort.sortBy === header.key && (
                                    <div className={`sorting-icon${header.sortDirection === SortDirection.desc ? '' : ' sorting-asc-icon'}`} />
                                )}
                            </div>


                            <div className='header-operation-wrapper'>
                                <div className="header-operation-container">
                                    {tableHeaders.filter(header => header.hidden).length > 0 && <button className="header-operation-button" onClick={toggleHeaderDropdown}>
                                        <AddCircleOutlineOutlined style={{ height: 20, width: 20 }} />
                                    </button>}
                                    <button className="header-operation-button" onClick={event => { event.stopPropagation(); hideColumn(header.key) }}>
                                        <RemoveCircleOutline style={{ height: 20, width: 20 }} />
                                    </button>
                                </div>
                                <div
                                    className='table_head_resizer'
                                    onMouseDown={(e) => {
                                        console.log("DOWn")
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const startX = e.clientX;
                                        const index = tableHeaders.findIndex(iterationHeader => iterationHeader.key === header.key)
                                        const startWidth = tableHeaders[index].width;
                                        const onMouseMove = (e) => {
                                            const newWidth = startWidth + (e.clientX - startX);
                                            handleColumnResize({ dataKey: header.key, newWidth });
                                        };
                                        const onMouseUp = () => {
                                            document.removeEventListener('mousemove', onMouseMove);
                                            document.removeEventListener('mouseup', onMouseUp);
                                            document.body.classList.remove('resizing');
                                        };
                                        document.addEventListener('mousemove', onMouseMove);
                                        document.addEventListener('mouseup', onMouseUp);
                                    }}>

                                </div>
                            </div>
                        </div>
                        <Popper
                            id={'header-popper'}
                            open={headerSelectOpen}
                            anchorEl={anchorElOpenHeaders}
                            placement="bottom-start"
                            disablePortal={false}
                            modifiers={[
                                {
                                    name: 'flip',
                                    enabled: true,
                                },
                                {
                                    name: 'preventOverflow',
                                    enabled: true,
                                    options: {
                                        boundariesElement: 'scrollParent'
                                    },
                                }
                            ]}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <div>
                                    <ClickAwayListener onClickAway={headerClickAwayHandler}>
                                        <Zoom {...TransitionProps} timeout={350}>
                                            <Paper
                                                elevation={3}
                                                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                                className='roundedcornes'
                                            >
                                                {tableHeaders.filter(header => header.hidden)
                                                    .map((column, index) => (
                                                        <Tooltip className="version-tooltip" title={column.label} placement="top">
                                                            <MenuItem className='version-option' key={column.key} onClick={event => handleColumnAdd(event, column.key)} style={{ backgroundColor: '#FFFFFF' }}>
                                                                {column.label}
                                                            </MenuItem>
                                                        </Tooltip>
                                                    ))}
                                            </Paper>
                                        </Zoom>
                                    </ClickAwayListener>
                                </div>
                            )}
                        </Popper>
                    </th>
                )
            })}
        </tr>
    )
}

export default TableHeaderRow;