import React from 'react';
import TableRow from './TableRow';
import { useAppSelector } from '../../../redux/store';

const TableContent = () => {
    const tableRows = useAppSelector(store => store.app.tableRows);

    return (
        <tbody>
            {tableRows.map((row, index) => {
                return <TableRow row={row} />
            })}
        </tbody>
    )
}

export default TableContent;