import React, { useEffect, useRef } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IHistorySession } from './IDashboard';
import '../Home/classess.css'
import './tablev3.css'
import { getSessionsWithShares } from '../../api/SessionsService';
import Table from './Table/Table';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setIsDashboardLoading, setTableRows, updateDashboardRefresh } from '../../redux/features/app/app';
import { IAppliedFilter, SessionState } from '../DashboardHeader/ISearch';

const parseSloDate = (dateStr: string): Date | null => {
    const arr = dateStr.split('.');
    if (arr.length !== 3) return null;
    const numArr = arr.map((v) => Number(v));
    let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
    date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
    return date;
};
const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
    const urlParams: URLSearchParams = new URLSearchParams()

    appliedFilters.forEach((filter: IAppliedFilter) => {
        switch (filter[0].field) {
            case 'createdAt':
                if (filter.length === 3) {//user passed specific day or open interval at one side
                    let date = parseSloDate(filter[2].label);
                    if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

                    if (filter[1].field === "to") {
                        date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const dateStr = date.toISOString();

                    switch (filter[1].field) {
                        case 'to':
                            urlParams.append('created-at', `;${dateStr}`)
                            break;
                        case 'from':
                            urlParams.append('created-at', `${dateStr};`)
                            break;
                        case 'is':
                            const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
                            urlParams.append('created-at', `${dateStr};${dateEnd}`)
                            break;
                        default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
                    }
                } else if (filter.length === 5) {//user passed closed interval on both sides
                    let dateOne = parseSloDate(filter[2].label)
                    let dateTwo = parseSloDate(filter[4].label)

                    if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
                    if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

                    if (filter[1].field === "from") {
                        dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
                    } else {
                        dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
                    const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

                    urlParams.append('created-at', `${startDate};${endDate}`)
                } else throw new Error('Date filters should be of length 3 or 5.')
                break;
            case 'labels':
                let labelFilterValue = filter[2].label
                if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
                else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
                urlParams.append('label', labelFilterValue)
                break;

            case 'name':
                let namelFilterValue = filter[2].label
                if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
                else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
                urlParams.append('name', namelFilterValue)
                break;

            case 'search':
                urlParams.append('name', `${filter[1].label}`)
                break;

            case 'user':
                // admin only
                let userFilterValue = filter[2].label
                if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
                urlParams.append('created-by-username', userFilterValue)
                break;
            case 'group':
                let groupFilterValue = filter[2].label
                if (filter[1].field === "contains") groupFilterValue = `%${groupFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${groupFilterValue}%`
                urlParams.append('created-by-group', groupFilterValue)
                break;
            case 'sessionSource':
                // admin only
                let sessionSourceFilterValue = filter[2].label
                if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
                else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
                urlParams.append('source', sessionSourceFilterValue)
                break;
            case 'sessionStatus':
                // admin only
                let sessionStatusFilterValue = filter[2].label
                let sessionStatusFilterField = filter[1].field

                if (sessionStatusFilterValue === SessionState.DELETED) {
                    if (sessionStatusFilterField === "isNot") {
                        urlParams.append('is-discarded', 'false')
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('is-discarded', 'true')
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                } else {
                    if (sessionStatusFilterField === "isNot") {
                        Object.keys(SessionState).forEach(sessionState => {
                            if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                                urlParams.append('status', sessionState)
                            }
                        })
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('status', sessionStatusFilterValue)
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                }
                break;
            default:
                break;
        }
    });
    return urlParams;
};

const V3Table = () => {
    const dispatch = useAppDispatch();
    const tableRows = useAppSelector(store => store.app.tableRows)
    const tableSort = useAppSelector(store => store.app.tableSort)
    const refreshDashboard = useAppSelector(store => store.app.refreshDashboard)
    const appliedFilters = useAppSelector(store => store.app.dashboardFilters)

    const lastRef = useRef<boolean>(false)

    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const fetchTop = async (cursor: string) => {
        if (tableRows.length < 1) return;
        dispatch(setIsDashboardLoading(true))

        const filters: URLSearchParams = transformFilters(appliedFilters);
        try {
            const {
                sessions: newSessions,
                last
            } = await getSessionsWithShares(
                cursor,
                -80,
                filters,
                { sortBy: tableSort.sortBy, sortDirection: tableSort.sortDirection }
            );

            const mapped: IHistorySession[] = newSessions.map(session => {
                return {
                    ...session.content,
                    shares: session.shares,
                    cursor: session.cursor,
                    expanded: false,
                    tagged: false,
                    openedVersion: false,
                    openedLabels: false,
                    summary: session.summary,
                    recordings: session.recordings
                }
            })

            lastRef.current = last

            if (mapped.length > 0) {
                dispatch(setTableRows(mapped.concat(tableRows.slice(0, 80))))
            }

            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTop = mapped.length * 30
            }
            dispatch(setIsDashboardLoading(false))
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBottom = async (cursor: string) => {
        if (tableRows.length < 1) return;
        dispatch(setIsDashboardLoading(true))
        const filters: URLSearchParams = transformFilters(appliedFilters);

        try {
            const {
                sessions: newSessions,
                last
            } = await getSessionsWithShares(
                cursor,
                80,
                filters,
                { sortBy: tableSort.sortBy, sortDirection: tableSort.sortDirection }
            );

            const mapped: IHistorySession[] = newSessions.map(session => {
                return {
                    ...session.content,
                    shares: session.shares,
                    cursor: session.cursor,
                    expanded: false,
                    tagged: false,
                    openedVersion: false,
                    openedLabels: false,
                    summary: session.summary,
                    recordings: session.recordings
                }
            })

            lastRef.current = last

            dispatch(setIsDashboardLoading(false))
            dispatch(setTableRows((tableRows.slice(-80).concat(mapped))))
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTop = 80 * 30
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchInitialSettings = async () => {
        if (!refreshDashboard) return;

        const filters: URLSearchParams = transformFilters(appliedFilters);

        dispatch(setIsDashboardLoading(true))
        try {
            const {
                sessions: newSessions,
                last
            } = await getSessionsWithShares(
                undefined,
                80,
                filters,
                { sortBy: tableSort.sortBy, sortDirection: tableSort.sortDirection }
            );

            lastRef.current = last

            const mapped: IHistorySession[] = newSessions.map(session => {
                return {
                    ...session.content,
                    shares: session.shares,
                    cursor: session.cursor,
                    expanded: false,
                    tagged: false,
                    openedVersion: false,
                    openedLabels: false,
                    summary: session.summary,
                    recordings: session.recordings
                }
            })

            dispatch(setIsDashboardLoading(false))
            dispatch(setTableRows(mapped))
            dispatch(updateDashboardRefresh(false))
        } catch (error) {
            console.log(error)
        }
    }

    const enteredMiddleRef = useRef<boolean>(false);

    useEffect(() => {
        const div = scrollContainerRef.current;
        if (!div) return;
        enteredMiddleRef.current = false;
        lastRef.current = false
        div.scrollTo({ top: 0 })
    }, [tableSort])

    const handleScroll = async () => {
        const scroller = scrollContainerRef.current
        if (!scroller) return;

        const scrollTop = scroller.scrollTop
        const scrollHeight = scroller.scrollHeight
        const clientHeight = scroller.clientHeight
        const isAtTop = scrollTop <= scrollHeight * 0.1;
        const isAtBottom = scrollTop >= scrollHeight - clientHeight - scrollHeight * 0.1;

        const enteredMiddle = enteredMiddleRef.current
        if (isAtTop && enteredMiddle) {
            const firstCursor = tableRows[0].cursor;
            enteredMiddleRef.current = false
            await fetchTop(firstCursor)
        } else if (isAtBottom && enteredMiddle && !lastRef.current) {
            const lastCursor = tableRows[tableRows.length - 1].cursor;
            enteredMiddleRef.current = false
            await fetchBottom(lastCursor)
        } else if (!isAtTop && !isAtBottom && !enteredMiddleRef.current) {
            enteredMiddleRef.current = true
        }
    }

    useEffect(() => {
        const div = scrollContainerRef.current;
        if (!div) return;
        div.addEventListener("scroll", handleScroll);

        return () => {
            div.removeEventListener("scroll", handleScroll);
        };
    }, [tableRows, tableSort])

    useEffect(() => {
        fetchInitialSettings();
    }, [tableSort, refreshDashboard, appliedFilters])

    useEffect(() => {

        const refreshInterval = setInterval(async () => {
            const numberOfSessions = tableRows.length

            if (numberOfSessions < 1) return;

            const filters: URLSearchParams = transformFilters(appliedFilters);

            try {

                const {
                    sessions: previousSessions,
                } = await getSessionsWithShares(
                    tableRows[0].cursor,
                    -1,
                    filters,
                    { sortBy: tableSort.sortBy, sortDirection: tableSort.sortDirection }
                );

                let previousCursor: string | undefined = undefined;
                if (previousSessions.length > 0) {
                    previousCursor = previousSessions[0].cursor
                }

                console.log(previousCursor)
                const {
                    sessions: newSessions,
                    last
                } = await getSessionsWithShares(
                    previousCursor,
                    numberOfSessions,
                    filters,
                    { sortBy: tableSort.sortBy, sortDirection: tableSort.sortDirection }
                );

                lastRef.current = last

                const mapped: IHistorySession[] = newSessions.map(session => {
                    const row = tableRows.filter(iterationSession => iterationSession.id === session.content.id);

                    let expanded = false;
                    if (row.length === 1 && row[0].expanded) expanded = true;

                    let openedVersion = false;
                    if (row.length === 1 && row[0].openedVersion) openedVersion = true;

                    let openedLabels = false;
                    if (row.length === 1 && row[0].openedLabels) openedLabels = true;

                    return {
                        ...session.content,
                        shares: session.shares,
                        cursor: session.cursor,
                        expanded,
                        tagged: false,
                        openedVersion,
                        openedLabels,
                        summary: session.summary,
                        recordings: session.recordings
                    }
                })

                dispatch(setTableRows(mapped))
            } catch (error) {
                console.log(error);
            }

        }, 1000 * 30)

        return () => {
            clearInterval(refreshInterval)
        }
    }, [tableRows, tableSort, refreshDashboard, appliedFilters])

    return (
        <div style={{ width: '80vw', margin: '0 auto', overflowX: 'scroll' }}>
            <div id="table_scroll_container" ref={scrollContainerRef} style={{ height: '70vh', overflow: 'scroll' }}>
                <DndProvider backend={HTML5Backend}>
                    {/*<NewTable updateData={() => {}} data={tableRows} />*/}
                    <Table />
                </DndProvider>
            </div>
        </div>
    )
}

export default V3Table;