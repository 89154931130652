import React, { ChangeEvent, createContext, useContext, useState, MouseEvent } from "react";
import Modal from "../Home/Modal";
import './new_speaker_modal.css';
import Add from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { cloneDeep } from "lodash";

interface SpeakersContextType {
    selectedSpeaker: IDummySpeaker | undefined;
    updateSpeaker: (newSpeaker: IDummySpeaker | undefined) => void;
    modalStage: SpeakerModalStage;
    updateModalStage: (newStage: SpeakerModalStage) => void;
}

const SpeakersContext = createContext<SpeakersContextType | undefined>(undefined);

interface SpeakersProviderProps {
    children: React.ReactNode;
}

export const SpeakerModalProvider: React.FC<SpeakersProviderProps> = ({ children }) => {
    const [selectedSpeaker, setSelectedSpeaker] = useState<IDummySpeaker | undefined>(undefined);
    const [modalStage, setModalStage] = useState<SpeakerModalStage>(SpeakerModalStage.list)

    const updateModalStage = (newStage: SpeakerModalStage) => setModalStage(newStage)
    const updateSpeaker = (newSpeaker: IDummySpeaker | undefined) => setSelectedSpeaker(newSpeaker)

    return (
        <SpeakersContext.Provider value={{ selectedSpeaker, updateSpeaker, modalStage, updateModalStage }}>
            {children}
        </SpeakersContext.Provider>
    );
};


export const useSpeakerModal = (): SpeakersContextType => {
    const context = useContext(SpeakersContext);
    if (!context) {
        throw new Error('useCounter must be used within a CounterProvider');
    }
    return context;
};

interface IDummySpeaker {
    createdAt: string,
    id: number;
    lastSelectedAt: string,
    name: string
    isLocal: boolean;
    hasChanged: boolean
}

//TODO: Roles are going to be on seperate property.
const speakerList: IDummySpeaker[] = [
    {
        createdAt: "2025-01-15T08:30:00Z",
        id: 1,
        lastSelectedAt: "2025-01-16T12:15:00Z",
        name: "[Moderator, Organizer]John Doe",
        isLocal: false,
        hasChanged: false
    },
    {
        createdAt: "2025-01-14T14:00:00Z",
        id: 2,
        lastSelectedAt: "2025-01-16T09:45:00Z",
        name: "[]Jane Smith",
        isLocal: false,
        hasChanged: true
    },
    {
        createdAt: "2025-01-10T10:20:00Z",
        id: 3,
        lastSelectedAt: "2025-01-16T08:00:00Z",
        name: "[Panelist]Michael Brown",
        isLocal: true,
        hasChanged: false
    },
    {
        createdAt: "2025-01-12T13:50:00Z",
        id: 4,
        lastSelectedAt: "2025-01-15T16:30:00Z",
        name: "[Speaker, Organizer, Panelist]Emily White",
        isLocal: false,
        hasChanged: false
    },
    {
        createdAt: "2025-01-11T09:10:00Z",
        id: 5,
        lastSelectedAt: "2025-01-14T14:40:00Z",
        name: "[]Chris Green",
        isLocal: false,
        hasChanged: false
    },
    {
        createdAt: "2025-01-09T15:30:00Z",
        id: 6,
        lastSelectedAt: "2025-01-16T10:15:00Z",
        name: "[Keynote Speaker]Sarah Blue",
        isLocal: false,
        hasChanged: true
    },
    {
        createdAt: "2025-01-13T11:00:00Z",
        id: 7,
        lastSelectedAt: "2025-01-16T07:50:00Z",
        name: "[Moderator, Panelist]David Black",
        isLocal: true,
        hasChanged: false
    },
    {
        createdAt: "2025-01-08T16:40:00Z",
        id: 8,
        lastSelectedAt: "2025-01-15T18:20:00Z",
        name: "[]Lisa Gray",
        isLocal: false,
        hasChanged: true
    }
];


interface ISpeakerRowProps {
    speaker: IDummySpeaker,
}
const SpeakerRow = ({ speaker }: ISpeakerRowProps) => {

    const {
        updateModalStage,
        updateSpeaker
    } = useSpeakerModal();

    const regex = /^\[([^\]]*)\](.*)$/; // Matches roles within brackets and the name
    const match = speaker.name.match(regex);

    let user = { roles: [] as string[], name: speaker.name.split("]")[1] }

    if (match) {
        const roles = match[1] ? match[1].split(",").map(role => role.trim()) : [];
        const actualName = match[2].trim();
        user = { roles, name: actualName };
    }

    const editSpeaker = () => {
        updateSpeaker(speaker)
        updateModalStage(SpeakerModalStage.edit_speaker)
    }

    const deleteSpeaker = () => {
        updateSpeaker(speaker)
        updateModalStage(SpeakerModalStage.delete_speaker)
    }

    const addLocalSpeaker = () => {
        updateSpeaker(speaker)
        updateModalStage(SpeakerModalStage.add_new_speaker)
    }

    const updateChangedSpeaker = () => {
        updateSpeaker(speaker)
        updateModalStage(SpeakerModalStage.change_speaker)
    }


    return (
        <div className="speaker_row_container">
            <p className={`speaker_row_name${speaker.isLocal || speaker.hasChanged ? " speaker_row_name_local_or_changed" : ""}`}>{user.name}{user.roles.length > 0 ? ` ( ${user.roles[0]} )` : ""}</p>
            <div>
                {!speaker.isLocal && !speaker.hasChanged && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <EditIcon onClick={editSpeaker} />
                        <DeleteForeverIcon onClick={deleteSpeaker} sx={{ color: '#FF0000' }} />
                    </div>
                ) || speaker.isLocal && (
                    <Add onClick={addLocalSpeaker} />
                ) || speaker.hasChanged && (
                    <SaveIcon onClick={updateChangedSpeaker} />
                )}
            </div>
        </div>
    )
}

const SpeakerModalList = () => {
    const {
        updateModalStage,
    } = useSpeakerModal();

    const addNewUser = () => updateModalStage(SpeakerModalStage.add_new_speaker);
    return (
        <>
            <div id="speaker_modal_header_wrapper">
                <div>
                    <p id="speaker_modal_header_title">Govorci</p>
                    <p id="speaker_modal_header_subtitle">Izbrani govorec: Janez Novak ( senator )</p>
                </div>
                <button onClick={addNewUser} id="speaker_modal_add_new_user_container">
                    <Add sx={{ color: "#F2F2F2", width: 20 }} />
                </button>
            </div>
            <input id="speaker_modal_search_input" value={""} placeholder='Išči govorca' />
            <div id="speaker_list_container">
                {speakerList.map(speaker => <SpeakerRow speaker={speaker} />)}
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={() => alert('Zapri')}>ZAPRI</button>
                <button id="spekaer_modal_save_button" onClick={() => alert('Dodaj')}>DODAJ</button>
            </div>
        </>
    )
}

const SpeakerModalDelete = () => {
    const {
        selectedSpeaker,
        updateModalStage,
        updateSpeaker
    } = useSpeakerModal();

    const cancelSpeakerDelete = () => {
        updateSpeaker(undefined);
        updateModalStage(SpeakerModalStage.list)
    }

    return (
        <div>
            <p id="speaker_modal_header_title">Izbriši govorca</p>
            <p style={{textWrap: 'wrap'}}>
                Ali ste prepičani, da želite izbrisati govorca{selectedSpeaker && (<strong>{` ${selectedSpeaker.name.split("]")[1] }?` }</strong>)}
            </p>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={cancelSpeakerDelete}>NE</button>
                <button id="spekaer_modal_save_button" onClick={() => alert('DA')}>DA</button>
            </div>
        </div>
    )
}


interface ISpeakerRole {
    label: string;
    default: boolean;
}
const SpeakerModalCreate = () => {
    const {
        updateModalStage,
    } = useSpeakerModal();

    const [speakerLabel, setSpeakerLabel] = useState<string>('');
    const [speakerRole, setSpeakerRole] = useState<string>('');
    const [speakerRoles, setSpeakerRoles] = useState<ISpeakerRole[]>([]);

    const updateSpeakerLabel = (event: ChangeEvent<HTMLInputElement>) => setSpeakerLabel(event.target.value)
    const updateSpeakerRole = (event: ChangeEvent<HTMLInputElement>) => setSpeakerRole(event.target.value)

    const addSpeakerRole = () => setSpeakerRoles(current => {
        if (speakerRole.length < 1) return current;

        current.push({label: speakerRole, default: current.length === 0})
        setSpeakerRole("");

        return current;
    })

    const changeDefaultRole = (index: number) => {
        const copy = cloneDeep(speakerRoles);
        copy.forEach((role, iterationIndex) => role.default = iterationIndex === index)
        setSpeakerRoles(copy)
    }

    const deleteRole = (event: MouseEvent<HTMLButtonElement>, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        const copy = cloneDeep(speakerRoles);
        const isDeletedDefault = copy[index].default
        
        copy.splice(index, 1);
        if (isDeletedDefault && copy.length > 0) {
            copy[0].default = true
        }

        setSpeakerRoles(copy)
    }

    const cancelSpeakerCreate = () => updateModalStage(SpeakerModalStage.list)

    return (
        <div>
            <div id="speaker_modal_header_wrapper">
                <p id="speaker_modal_header_title">Dodaj govorca</p>
            </div>
            <input className="add_user_input" value={speakerLabel} placeholder="Janez Novak..." onChange={updateSpeakerLabel} />
            <div id="speaker_modal_role_input_container">
                <input className="add_user_input" id="speaker_modal_role_input" value={speakerRole} placeholder="Odvetnik..." onChange={updateSpeakerRole} />
                <button onClick={addSpeakerRole}>
                    <Add sx={{ color: "#2b8dff" }} />
                </button>
            </div>
            <div id="speaker_roles_container">
                {speakerRoles.map((iterationRole, index) => {
                    return (
                        <button onClick={() => changeDefaultRole(index)} className={`speaker_role${iterationRole.default ? " speaker_role_default" : ""}`}>
                            <button onClick={event => deleteRole(event, index)} className="speaker_role_delete">
                                <div className={`speaker_role_delete_container${iterationRole.default ? " speaker_role_delete_container_default" : ""}`}>
                                    <CloseIcon sx={{color: iterationRole.default ? "#2b8dff" : "#F2F2F2", width: 18, height: 18 }} />
                                </div>
                            </button>
                            <p>{iterationRole.label}</p>
                        </button>     
                    )
                })}
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={cancelSpeakerCreate}>NAZAJ</button>
                <button id="spekaer_modal_save_button" onClick={() => alert('DA')}>DODAJ</button>
            </div>
        </div>
    )
}

const SpeakerModalReplace = () => {
    const {
        selectedSpeaker,
        updateModalStage
    } = useSpeakerModal();

    const regex = /^\[([^\]]*)\](.*)$/; // Matches roles within brackets and the name
    const match = selectedSpeaker ? selectedSpeaker.name.match(regex) : null;

    let user = { roles: [] as string[], name: selectedSpeaker && selectedSpeaker.name.split("]")[1] }

    if (match) {
        const roles = match[1] ? match[1].split(",").map(role => role.trim()) : [];
        const actualName = match[2].trim();
        user = { roles, name: actualName };
    }


    return (
        <div>
            <p id="speaker_modal_header_title">Zamenjaj govorca</p>
            <p>Novi govorec {user.name && user.name}</p>

            <p>Vloga novega govorca</p>
            {user.roles.map(role => {
                return (
                    <p>{role}</p>
                )
            })}
        </div>
    )
}

enum SpeakerModalStage {
    list = "list",
    add_new_speaker = "add_new_speaker",
    edit_speaker = "edit_speaker",
    change_speaker = "change_speaker",
    delete_speaker = "change_speaker",
}

const NewSpeakerModal = () => {
    const {modalStage} = useSpeakerModal();
    return (
        <Modal showClose={false} visible={false} closeModal={() => { }} title={''}>
            <>
                {modalStage === SpeakerModalStage.list && <SpeakerModalList />}
                {modalStage === SpeakerModalStage.delete_speaker && <SpeakerModalDelete />}
                {modalStage === SpeakerModalStage.add_new_speaker && <SpeakerModalCreate />}
                
            </>
        </Modal>
    )

}

const NewSpeakerModalWrapper = () => {
    return (
        <SpeakerModalProvider>
            <NewSpeakerModal />
        </SpeakerModalProvider>
    )
}

export default NewSpeakerModalWrapper;