import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IChipData, IHistorySession, ISession } from '../IDashboard';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setTableRows, toggleVersionModal, updateSessionNotes, removeLabel, toggleLabel, addLabel, setSessionsLabels, toggleLabelModal, updateDashboardRefresh, setSpeakerSettingsModal, setSessionName, setIsSessionDiscarded, setIsDashboardLoading, setEditorLock, setTbFormat, setValidRedirect, setEditorMode, setAudioInfo, setIsDashboardReady, setDiarization } from '../../../redux/features/app/app';
import './table_row.css';
import { addNewLabelToSession, cancelSessionInProgress, copySession, deleteSession, getAllDataAboutSessionAdmin, getClientLabels, getEditorContentV30, getExpandedSessionDetails, getNewSessionLock, getSession, getSessionLabels, getSessionRecordings, getSessionTranscripts, patchEditorContentV30, patchSessionInfo, recreateSession, registerNewClientLabel, removeLabelFromSession, updateSessionLabel } from '../../../api/SessionsService';
import { IRecordingsArray } from '../../Home/ISettings';
import { EditorModeEnums, TableHeaders, UserRoleEnums } from '../../../redux/store/IStore';
import { SessionState } from '../../DashboardHeader/ISearch';
import { ClickAwayListener, MenuItem, Paper, Popper, TextField, Tooltip, Zoom } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import { ArrowDropDownIcon } from '../../Icons/ArrowDropDownIcon';
import { enqueueSnackbar } from 'notistack';
import { useDebounce } from '../../../hooks/useDebounce';
import RecordingsTable from './RecordingsTable';
import { DeleteLabelIcon } from '../../Icons/DeleteIcon';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { CancelIcon } from '../../Icons/CancelIcon';
import { RestartIcon } from '../../Icons/RestartIcon';
import usePipeline from '../../../hooks/usePipeline';
import saveAs from 'file-saver';
import ShareModal from '../ShareModal';
import DeletionConfirmation from '../DeletionConfirmation';
import { AccessMode, IPutSessionLock } from '../../../types';
import { IGetV30 } from '../../../api/types';
import moment from 'moment';
import { urlBackend } from '../../../providers/V3WebSocketProvider';
import { API, CLIENT, SESSIONS } from '../../../api/endpoints';
import { useNavigate } from 'react-router-dom';
import { IV3RecievedTranscript } from '../../Libraries/ILibraries';
import { paragraphTokens } from '../../../shared/paragrapher';
import { convertFromVersion3ToVersion2File } from '../../../api/dataConverters';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../../shared/DataConverters';
import { convertToRaw } from 'draft-js';

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const translateStatusEnum = (value: SessionState, numberOfVersions: number): string => {
    switch (value) {
        case SessionState.DELETED: return "IZBRISANO"
        case SessionState.INITIALIZING: return "INICIALIZACIJA"
        case SessionState.IN_QUEUE: return "V VRSTI"
        case SessionState.IN_PROGRESS: return "V PROCESIRANJU"
        case SessionState.FINISHED: return `UREDI${numberOfVersions > 0 ? ".V" + numberOfVersions : ""}`
        case SessionState.CANCELED: return "PREKINJENO"
        case SessionState.ERROR: return "NAPAKA"
        default: return value
    }
}

function convertAndFormatTimestamp(isoTimestamp: string) {
    // Parse the ISO 8601 timestamp
    const date = DateTime.fromISO(isoTimestamp, { zone: 'local' });

    // Format the date according to the specified format
    const formattedDate = date.toFormat('d.M.yyyy HH:mm:ss');

    return formattedDate;
}

const formatDuration = (ms: number) => Duration.fromMillis(ms).toFormat("hh:mm:ss").toString();

interface ITableRowProps {
    row: IHistorySession;
}
const ExpandedRow = ({ row }: ITableRowProps) => {
    const user = useAppSelector(state => state.app.user);
    const tableHeaders = useAppSelector(store => store.app.tableHeaders);
    const availableLabels = useAppSelector(state => state.app.sessionsLabels);
    const activeConfiguration = useAppSelector(store => store.app.activeConfiguration);

    const isHardDeleter = user && user.userRoles.includes(UserRoleEnums.SESSION_HARD_DELETE) || false

    const [newLabel, setNewLabel] = useState<string>('');
    const [notes, setNotes] = useState<string>(row.notes);
    const [shareModal, setShareModal] = useState<boolean>(false);
    const [summary, setSummary] = useState<string | undefined>(undefined);
    const [recordings, setRecordings] = useState<IRecordingsArray | undefined>(undefined);
    const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<boolean>(false);

    const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);
    const addButtonOpen = Boolean(anchorElAddLabel);
    const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

    const debouncedNotes = useDebounce(notes, 500, []);
    const { buildPipeline } = usePipeline();
    const dispatch = useAppDispatch();

    const setLocalNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value)
    }

    const fetchinfo = async () => {
        try {
            const [summaryData, recordingsData] = await getExpandedSessionDetails(row.id);
            setSummary(summaryData.data.summary)
            setRecordings(recordingsData.data);
        } catch (error) {
            console.log(error)
        }
    }

    const removeLabelWrapper = (event: React.MouseEvent<HTMLElement>, label: IChipData) => {
        event.stopPropagation();
        dispatch(removeLabel({ id: row.id, labelId: label.label.id }));
        removeLabelFromSession(row.id, label.label.id).then(() => {
            enqueueSnackbar(`Labela ${label.label.code} uspešno izbrisana.`, { variant: 'success' })
        }).catch(e => {
            dispatch(addLabel({ id: row.id, label }))
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Labele ni možno izbrisati - seja se je vmes spremenila.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        })
    }

    const toggleLabelWrapper = (label: IChipData) => {
        dispatch(toggleLabel({ id: row.id, labelId: label.label.id, enabled: !label.isEnabled }))
        updateSessionLabel(row.id, label.label.id, !label.isEnabled).catch(e => {
            dispatch(toggleLabel({ id: row.id, labelId: label.label.id, enabled: label.isEnabled }))
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Labele ni možno izbrisati - seja se je vmes spremenila.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        })
    }

    const openLabels = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (row.openedVersion) dispatch(toggleVersionModal(row))
        if (!anchorElAddLabel) {
            const target = event.currentTarget;
            getClientLabels().then(({ data }) => {
                dispatch(setSessionsLabels(data));
                setAnchorElAddLabel(target);
            });
            dispatch(toggleLabelModal({ id: row.id }))
        } else setAnchorElAddLabel(null);
    }
    const cancelSessionProggress = async () => {
        try {
            await cancelSessionInProgress(row.id);
            dispatch(updateDashboardRefresh(true))
        } catch (error) {
            console.log(error)
        }
    }
    const recreateSessionCallback = async () => {
        //TODO: Add modal toggle if diarization is turned on.

        if (activeConfiguration.model && activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) {
            dispatch(setSpeakerSettingsModal({ visible: true, retranscription: true }));
            return;
        }

        retranscribe();
    }
    const downloadSession = () => {
        //TODO: handle error
        getAllDataAboutSessionAdmin(row.id)
            .then(
                ([
                    { data: dSession },
                    { data: dSummary },
                    {
                        data: dRecordings,
                    },
                    { data: dTranscripts },
                ]) => {
                    const printable = {
                        session: { ...dSession, ...dSummary },
                        recordings: dRecordings,
                        transcripts: dTranscripts.map((transcript, index) => ({
                            index,
                            content: transcript,
                        })),
                    };

                    var data = new Blob([JSON.stringify(printable, null, 4)], { type: 'application/json' });

                    saveAs(data, `truebar-${row.id}.json`);
                }
            )
            .catch((e) => {
                const error = e as any;
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            });
    }
    const openShare = () => setShareModal(true)
    const copySessionHandler = async () => {
        try {
            await copySession(row.id)
            enqueueSnackbar('Seja uspešno kopirana!', { variant: 'success' });
            dispatch(updateDashboardRefresh(true))
        } catch (e) {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        }
    }

    const retranscribe = async () => {
        try {
            const pipeline = buildPipeline();

            if (!pipeline) {
                enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' })
                return;
            }
            await recreateSession(row.id, pipeline);
            dispatch(updateDashboardRefresh(true))
        } catch (e) {
            const error = e as any;
            if (error.response.status === 402) {
                enqueueSnackbar("Porabili ste razpoložljivo kvoto za transkripcijo. Kontaktirajte podporo.", { variant: 'error' })
            } else if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        }
    }

    const clickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (anchorElAddLabel) {
            dispatch(toggleLabelModal({ id: row.id }))
            setAnchorElAddLabel(null);
        }
    };

    const toggleDeletion = () => setDeletionConfirmationModal(!deletionConfirmationModal)

    const deleteSessionHandler = async () => {
        try {
            await deleteSession(row.id, row.isDiscarded && isHardDeleter)
            const rowHeaders = Array.from(document.getElementsByClassName('row-header')).concat(Array.from(document.getElementsByClassName('row-header-deleted')))
            for (let rowHeaderIndex = 0; rowHeaderIndex < rowHeaders.length; rowHeaderIndex++) {
                const row = rowHeaders[rowHeaderIndex]
                if (!row) continue;

                const key = row.getAttribute('identifier')
                if (key === row.id.toString()) {
                    row.classList.remove('selected-row-header')
                    continue;
                } else if (key === null) {
                    continue;
                }

                row.classList.remove('blurred-row-header')
            }
            setDeletionConfirmationModal(false)
            dispatch(updateDashboardRefresh(true))
            enqueueSnackbar('Seja uspešno izbrisana!', { variant: 'success' });
        } catch (e) {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Brisanje seje ni uspelo! Kontaktirajte tehnično podporo.`)
            }
        }
    }

    const addNewLabel = (labelId: number, code: string, color: string, event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        if (event) {
            event.stopPropagation();
        }
        addNewLabelToSession(row.id, labelId, true).then(() => {
            dispatch(addLabel({
                id: row.id, label: {
                    isEnabled: true,
                    label: {
                        id: labelId,
                        code,
                        color,
                    },
                }
            }))

            setNewLabel('');
            setAnchorElAddLabel(null);
        }).catch(e => {
            const error = e as any;
            if (error.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (error.response.status === 404) {
                enqueueSnackbar("Labele ni možno dodati - seja se je vmes spremenila.", { variant: 'error' })
            } else if (error.response.status === 409) {
                enqueueSnackbar(`Labela ${code} je že shranjena.`, { variant: 'error' })
            } else if (error.response.status === 423) {
                enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
            }
        });
    };

    const registerNewLabel = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        const color = generateColor();

        if (
            availableLabels.some(({ code }) => code === newLabel) ||
            row.labels.some(({ label: { code } }) => code === newLabel)
        ) {
            enqueueSnackbar(`Labela "${newLabel}" že obstaja.`, {
                variant: 'error',
                autoHideDuration: 4000,
            });

            return;
        }

        registerNewClientLabel(newLabel, color)
            .then(({ data: { id } }) => addNewLabel(id, newLabel, color))
            .catch((e) => {
                const labelId = e.response?.data?.id;

                const error = e as any;
                if (error.response.status === 409) {
                    enqueueSnackbar(`Labela ${newLabel} že obstaja.`, { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
                }

                if (labelId) {
                    setNewLabel('');
                    addNewLabelToSession(row.id, labelId, true).then(() => {
                        getSessionLabels(row.id).then(({ data }) => {
                            dispatch(addLabel({ id: data.id, label: data }))
                        });
                    });
                }
            });
    };

    const generateColor = () => tempColours[availableLabels.length % tempColours.length];
    const smallerIds = new Set(row.labels.map(item => item.label.id));


    const onLabelInputclick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    useEffect(() => {
        if (!row.openedLabels) setAnchorElAddLabel(null);
    }, [row.openedLabels])

    useEffect(() => {
        if (row.expanded) {
            console.log(`Expanded row with name: ${row.name}`)
            fetchinfo();
        }
    }, [row.expanded])

    const initialRef = useRef<boolean>(true);
    useEffect(() => {
        const wrapper = async () => {
            try {
                await patchSessionInfo({ notes: debouncedNotes }, row.id)
                enqueueSnackbar("Zapiski uspešno posodobljeni.", { variant: "success" })
                dispatch(updateSessionNotes({ id: row.id, newNotes: debouncedNotes as string }))
            } catch (e) {
                const error = e as any;
                if (error.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (error.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else if (error.response.status === 423) {
                    enqueueSnackbar("Seja je zaklenjena.", { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${error.response.data.id}`, { variant: "autoCopyError", autoHideDuration: null })
                }
                console.log(error)
            }
        }

        !initialRef.current ? wrapper() : initialRef.current = false
    }, [debouncedNotes])

    return (
        <>
            <ShareModal
                open={shareModal}
                handleClose={setShareModal}
                modalTitle={row.name}
                sessionId={row.id}
                setSharedWithOthers={() => { }}
            />

            <DeletionConfirmation
                hardDelete={row.isDiscarded && isHardDeleter}
                open={deletionConfirmationModal}
                handleClose={toggleDeletion}
                deleteSessionCallback={deleteSessionHandler}
            />

            <Popper
                id={addButtonId}
                open={addButtonOpen}
                anchorEl={anchorElAddLabel}
                placement="right-end"
                disablePortal={false}
                style={{ zIndex: 100000 }}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent'
                        },
                    }
                ]}
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={clickAwayHandler}>
                        <Zoom {...TransitionProps} timeout={350}>
                            <Paper
                                elevation={3}
                                className="accordion-chip-popper-container roundedcornes"
                                style={{ marginLeft: '-5px' }}
                                square={false}>
                                <div className='accordion-chip-popper-wrapper'>
                                    {availableLabels.filter(label => !smallerIds.has(label.id))
                                        .map(({ id, code, color }) => (
                                            <MenuItem key={id} onClick={event => addNewLabel(id, code, color, event)}>
                                                <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                                                {code}
                                            </MenuItem>
                                        ))}

                                    {availableLabels.filter(label => !smallerIds.has(label.id)).length === 0 && (
                                        <MenuItem key='-1'>
                                            Na voljo ni nobena labela
                                        </MenuItem>
                                    )}
                                </div>

                                <div className="accordion-chip-popper-footer">
                                    <AddCircleOutlineOutlined
                                        fontSize="small"
                                        className="accordion-chip-popper-footer-add"
                                        style={{ color: generateColor() }}
                                    />
                                    <TextField
                                        fullWidth
                                        placeholder="Vnesi ime labele"
                                        value={newLabel}
                                        spellCheck={false}
                                        onChange={(e) => setNewLabel(e.target.value)}
                                        variant="outlined"
                                        className='paddingoutline'
                                        onClick={onLabelInputclick}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') registerNewLabel(ev);
                                        }}
                                    />
                                </div>
                            </Paper>
                        </Zoom>
                    </ClickAwayListener>
                )}
            </Popper>
            <tr className={`expand_row${row.expanded ? " expanded" : ""}`}>
                <td colSpan={tableHeaders.filter(tableHeader => !tableHeader.hidden).length} style={{ backgroundColor: "#E6E6E6" }}>
                    <div style={{ height: '350px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80vw', padding: '12px 24px', position: 'sticky', left: 0 }}>
                        <div style={{ flex: 1 }}>
                            <p className='detail_title'>Predogled</p>
                            <div className='preview-contents'>
                                <p className='preview-contents-text'>
                                    {summary}
                                </p>
                            </div>

                            <div className='notes-container'>
                                <p className='notes-header'>Zapiski</p>
                                <textarea
                                    className='notes-textarea'
                                    placeholder='Vnesi tekst tukaj'
                                    value={notes}
                                    onChange={setLocalNotes}
                                />
                            </div>

                            {row.labels.length > 0 && (
                                <div style={{ marginTop: "24px" }}>
                                    <p className='notes-header'>Labele</p>
                                    <div className='labels-container'>
                                        {row.labels.map((label, index) => {
                                            return (
                                                <div className='label-wrapper' key={label.label.id} onClick={() => toggleLabelWrapper(label)} style={{
                                                    backgroundColor: label.isEnabled ? label.label.color : 'transparent',
                                                    border: `1px solid ${label.isEnabled ? 'transparent' : label.label.color}`,
                                                }}>
                                                    <p className='label-button-text' style={{ color: label.isEnabled ? '#FFF' : label.label.color }}>{label.label.code}</p>
                                                    <button data-testid={`delete-label-${label.label.code}`} className='delete-label-button' onClick={event => removeLabelWrapper(event, label)}>
                                                        <DeleteLabelIcon fill={label.isEnabled ? "#FFF" : label.label.color} />
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={{ flex: 1 }}>
                            <RecordingsTable recordings={recordings} />
                        </div>
                    </div>
                    <div className="expanded_row_toolbar" style={{ width: '80vw', position: 'sticky', left: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button onClick={openLabels} className='manage-labels-button' />
                        <div>
                            {row.status === SessionState.IN_QUEUE && (
                                <Tooltip title="Prekini ponovno transkribiranje" placement="top" arrow>
                                    <button onClick={cancelSessionProggress} className='cancel-session-button'>
                                        <CancelIcon />
                                    </button>
                                </Tooltip>
                            )}
                            {(row.status === SessionState.FINISHED || row.status === SessionState.ERROR || row.status === SessionState.CANCELED) && (
                                <Tooltip title="Ponovno transkribiraj" placement="top" arrow>
                                    <button onClick={recreateSessionCallback} className='recreate-button'>
                                        <RestartIcon />
                                    </button>
                                </Tooltip>
                            )}
                            <Tooltip title="Izvozi podrobnosti seje" placement="top" arrow>
                                <button onClick={downloadSession} className='download-button' />
                            </Tooltip>
                            <Tooltip title="Deli sejo" placement="top" arrow>
                                <button onClick={openShare} className='share-button' />
                            </Tooltip>
                            <Tooltip title="Podvoji sejo" placement="top" arrow>
                                <button onClick={copySessionHandler} className='copy-button' />
                            </Tooltip>
                            {(!row.isDiscarded || (row.isDiscarded && isHardDeleter)) && (
                                <Tooltip title="Briši sejo" placement="top" arrow>
                                    <button onClick={toggleDeletion} className='delete-button' />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

const TableRow = ({
    row
}: ITableRowProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(store => store.app.user);
    const tableRows = useAppSelector(store => store.app.tableRows);
    const editorLock = useAppSelector(store => store.app.editorLock);
    const tableHeaders = useAppSelector(store => store.app.tableHeaders);
    

    const [anchorElOpenVersions, setAnchorElOpenVersions] = useState<null | HTMLElement>(null);
    const versionSelectOpen = Boolean(anchorElOpenVersions);
    const versionSelectButtonId = versionSelectOpen ? 'simple-popper' : undefined;

    const expandRow = () => {
        if (row.openedLabels || row.openedVersion) return;
        const copy = cloneDeep(tableRows)
        const tableScroller = document.getElementById("table_scroll_container");
        if (!tableScroller) return;

        copy.forEach(session => {
            if (session.id === row.id) {
                session.expanded = !session.expanded
            } else {
                session.expanded = false
            }
        })


        dispatch(setTableRows(copy))
    }

    const toggleVersionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (row.openedLabels) dispatch(toggleLabelModal({ id: row.id }))

        if (!anchorElOpenVersions) {
            const target = event.currentTarget;
            setAnchorElOpenVersions(target.parentElement)
            dispatch(toggleVersionModal(row))
        } else {
            dispatch(toggleVersionModal(row))
            setAnchorElOpenVersions(null);
        }
    }

    const versionClickAwayHandler = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (anchorElOpenVersions) {
            setAnchorElOpenVersions(null);
            dispatch(toggleVersionModal(row));
        }
    };

    const onVersionClick = (event: React.MouseEvent<HTMLElement>, isoDate: string) => {
        event.preventDefault();
        event.stopPropagation();
        openSession(event, isoDate)
        setAnchorElOpenVersions(null);
        dispatch(toggleVersionModal(row));
    }

    const openSession = async (event: React.MouseEvent<HTMLElement>, version?: string) => {
        if (!user) return;
        const {
            id,
            name,
            allRecordingsDiscarded,
            versionTags,
            isLocked,
            status
        } = row

        //setSessionIsOpening();
        if (status === SessionState.DELETED) {
            enqueueSnackbar("Seje, ki je bila izbrisana ni možno urejati.", { variant: 'error' })
            return;
        }

        if (status !== SessionState.FINISHED) {
            enqueueSnackbar("Seje, ki ni bila pravilno zaključena ni možno urejati.", { variant: 'error' })
            return;
        }

        if (typeof name === 'string') {
            dispatch(setSessionName(name));
        }
        dispatch(setIsSessionDiscarded(allRecordingsDiscarded));
        dispatch(setIsDashboardLoading(true));

        if (versionTags.length > 0) {
            const latestVersion = version ? version : versionTags.slice().sort((a, b) => b.localeCompare(a))[0];

            if (isLocked) {
                try {
                    const r = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                    const newLock = {
                        ...editorLock,
                        sessionLockKey: null,
                        sessionId: id,
                        refreshAfter: null,
                        editTicket: null,
                        versionName: latestVersion,
                    }
                    dispatch(setEditorLock(newLock))
                    //updateLocalEditorLock(newLock)

                    const { rawContentState } = JSON.parse(r.data.content);

                    if (rawContentState) {
                        dispatch(
                            setTbFormat({
                                rawContentState: rawContentState,
                                sessionId: id,
                            })
                        );
                    } else {
                        dispatch(
                            setTbFormat({
                                editorStateStringified: r.data.content as string,
                                sessionId: id,
                            })
                        );
                    }
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 409) {
                        enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }
            } else {
                let lock: IPutSessionLock | null = null
                try {
                    lock = (await getNewSessionLock(id, null)).data;
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 423) {
                        try {
                            const readResponse = await getEditorContentV30(id, null, AccessMode.READ_ONLY, latestVersion);
                            const newLock = {
                                ...editorLock,
                                sessionLockKey: null,
                                sessionId: id,
                                refreshAfter: null,
                                editTicket: null,
                                versionName: latestVersion,
                            }
                            dispatch(setEditorLock(newLock))
                            //updateLocalEditorLock(newLock)

                            const { rawContentState } = JSON.parse(readResponse.data.content);

                            if (rawContentState) {
                                dispatch(
                                    setTbFormat({
                                        rawContentState: rawContentState,
                                        sessionId: id,
                                    })
                                );
                            } else {
                                dispatch(
                                    setTbFormat({
                                        editorStateStringified: readResponse.data.content as string,
                                        sessionId: id,
                                    })
                                );
                            }
                        } catch (error) {
                            const mappedError = error as any
                            if (mappedError.response.status === 403) {
                                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                            } else if (mappedError.response.status === 404) {
                                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                            } else if (mappedError.response.status === 409) {
                                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                            } else {
                                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                            }
                        }
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }

                if (!lock) return;

                let contentResponse: IGetV30 | null = null
                try {
                    contentResponse = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, latestVersion)).data;
                } catch (error) {
                    const mappedError = error as any
                    if (mappedError.response.status === 403) {
                        enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                    } else if (mappedError.response.status === 404) {
                        enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                    } else if (mappedError.response.status === 409) {
                        enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                    } else if (mappedError.response.status === 423) {
                        enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
                    } else {
                        enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                    }
                }

                if (!contentResponse) return;

                const newLock = {
                    ...editorLock,
                    sessionLockKey: lock.key,
                    sessionId: id,
                    refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                    editTicket: contentResponse.editTicket,
                    versionName: latestVersion,
                }

                dispatch(setEditorLock(newLock))

                const { rawContentState } = JSON.parse(contentResponse.content);

                if (rawContentState) {
                    dispatch(
                        setTbFormat({
                            rawContentState: rawContentState,
                            sessionId: id,
                        })
                    );
                } else {
                    dispatch(
                        setTbFormat({
                            editorStateStringified: contentResponse.content as string,
                            sessionId: id,
                        })
                    );
                }
            }

            dispatch(setIsDashboardLoading(false));

            dispatch(setValidRedirect(true));
            dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));

            const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

            dispatch(
                setAudioInfo({
                    url: audioUrlPath,
                    loadNew: true,
                })
            );


            dispatch(setIsDashboardReady(false))
            navigate('/editor');
            return;
        }


        //From Transcripts below, from content above

        let lock: IPutSessionLock | null = null;
        try {
            lock = (await getNewSessionLock(id, null)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!lock) return;


        let contentResponse: IGetV30 | null = null
        try {
            contentResponse = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, null)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 409) {
                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!contentResponse) return;

        let transcriptsResponse: IV3RecievedTranscript[] | null = null;
        try {
            transcriptsResponse = (await getSessionTranscripts(id)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!transcriptsResponse) return;

        const paragraphedTranscript = paragraphTokens(transcriptsResponse, undefined, row.recordedMs);
        const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
        const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted) //convertTranscriptToEditorState(converted[0].content)

        const sessionRecordingPagination = await getSessionRecordings(id);
        if (sessionRecordingPagination.data.length < 1) {
            throw new Error("Session has no recordings")
        }

        let recordings: IRecordingsArray | null = null;
        try {
            recordings = (await getSessionRecordings(id)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!recordings || recordings.length < 0) return;


        const pipeline = sessionRecordingPagination.data[0].pipeline
        let diarization = false;
        pipeline.forEach(tag => {
            if (tag.config.parameters.enableSd) {
                diarization = tag.config.parameters.enableSd
            }
        })

        if (!diarization) {
            try {
                await patchEditorContentV30(
                    { rawContentState: convertToRaw(newEditorState.getCurrentContent()) },
                    id,
                    contentResponse.editTicket,
                    lock.key,
                );
            } catch (error) {
                const mappedError = error as any
                if (mappedError.response.status === 403) {
                    enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
                } else if (mappedError.response.status === 404) {
                    enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
                } else if (mappedError.response.status === 409) {
                    enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
                } else if (mappedError.response.status === 423) {
                    enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
                } else {
                    enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
                }
            }
        }

        //if (!patchSuccess) return;

        let sessionData: ISession | null = null;
        try {
            sessionData = (await getSession(id)).data
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!sessionData) return;

        const tag = sessionData.versionTags[0]

        let secondContent: IGetV30 | null = null;
        try {
            secondContent = (await getEditorContentV30(id, lock.key, AccessMode.READ_WRITE, tag)).data;
        } catch (error) {
            const mappedError = error as any
            if (mappedError.response.status === 403) {
                enqueueSnackbar("Za urejanje seje nimate ustreznih pravic.", { variant: 'error' })
            } else if (mappedError.response.status === 404) {
                enqueueSnackbar("Podatki za sejo ne obstajajo.", { variant: 'error' })
            } else if (mappedError.response.status === 409) {
                enqueueSnackbar(`Seja še ni zaključena.`, { variant: 'error' })
            } else if (mappedError.response.status === 423) {
                enqueueSnackbar(`Seja je zaklenjena.`, { variant: 'error' })
            } else {
                enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
            }
        }

        if (!secondContent) return;

        const latestVersion = version ? version : sessionData.versionTags.slice().sort((a, b) => b.localeCompare(a))[0];
        const newLock = {
            ...editorLock,
            sessionLockKey: lock.key,
            sessionId: id,
            refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
            editTicket: secondContent.editTicket,
            versionName: latestVersion,
        }
        dispatch(setEditorLock(newLock))

        if (diarization) {
            const newLock = {
                ...editorLock,
                sessionLockKey: lock.key,
                sessionId: id,
                refreshAfter: lock.expiresAt ? moment(lock.expiresAt).diff(moment.utc(), 'seconds') - 5 : 55,
                editTicket: contentResponse.editTicket,
                versionName: null,
            }
            dispatch(setEditorLock(newLock))
            dispatch(setDiarization(true))
        }

        dispatch(
            setTbFormat({
                editorState: newEditorState,
                sessionId: id,
            })
        );


        dispatch(setValidRedirect(true));
        dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));

        const audioUrlPath = `${urlBackend}/${API}/${CLIENT}/${SESSIONS}/${id}/audio.wav?access_token=${user.accessToken}&nocache=${new Date().getTime()}`;

        dispatch(
            setAudioInfo({
                url: audioUrlPath,
                loadNew: true,
            })
        );

        dispatch(setIsDashboardLoading(false))
        dispatch(setIsDashboardReady(false))
        navigate('/editor');
    }

    useEffect(() => {
        if (!row.openedVersion) setAnchorElOpenVersions(null);
    }, [row.openedVersion])

    return (
        <>
            <tr onClick={expandRow} className={`table_row${row.expanded ? "_expanded" : ""}`} key={row.id}>
                {tableHeaders.map(tableHeader => {
                    if (tableHeader.hidden) return;
                    if (tableHeader.key === TableHeaders.sources) {
                        return <td className='table_row_cell' style={{ width: tableHeaders.filter(header => header.key === 'sources')[0].width }}>{row.sources.map(source => source)}</td>
                    } else if (tableHeader.key === TableHeaders.labels) {
                        return <td className='table_row_cell labels_cell' style={{ width: tableHeaders.filter(header => header.key === 'labels')[0].width }}>
                            {row.labels.map(label => {
                                return (
                                    <Tooltip title={label.label.code} placement='top' arrow>
                                        <div className='label_cell_square' style={{ backgroundColor: label.label.color }}></div>
                                    </Tooltip>
                                )
                            })}
                        </td>;
                    } else if (tableHeader.key === TableHeaders.status) {
                        let className = ""
                        if (row.status === SessionState.CANCELED) className += row.expanded ? "canceled_session_expanded" : "canceled_session"
                        else if (row.status === SessionState.DELETED) className += "deleted_session"
                        else if (row.status === SessionState.ERROR) className += row.expanded ? "error_session_expanded" : "error_session"
                        else if (row.status === SessionState.FINISHED) className += row.expanded ? "finished_session_expanded" : "finished_session"
                        else if (row.status === SessionState.INITIALIZING) className += "initializing_session"
                        else if (row.status === SessionState.IN_PROGRESS) className += "in_progress_session"
                        else if (row.status === SessionState.IN_QUEUE) className += "in_queue_session"

                        return (
                            <td className='table_row_cell' style={{ width: tableHeader.width }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <p onClick={event => openSession(event)} className={className}>{translateStatusEnum(row.status, row.versionTags.length)}</p>
                                    {!row.isDiscarded && row.versionTags.length > 1 && <ArrowDropDownIcon onClick={toggleVersionsDropdown} />}
                                    <Popper

                                        id={versionSelectButtonId}
                                        open={versionSelectOpen}
                                        anchorEl={anchorElOpenVersions}
                                        placement="bottom-start"
                                        disablePortal={false}
                                        style={{ width: tableHeader.width }}
                                        modifiers={[
                                            {
                                                name: 'flip',
                                                enabled: true,
                                            },
                                            {
                                                name: 'preventOverflow',
                                                enabled: true,
                                                options: {
                                                    boundariesElement: 'scrollParent'
                                                },
                                            }
                                        ]}
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <div>
                                                <ClickAwayListener onClickAway={versionClickAwayHandler}>
                                                    <Zoom {...TransitionProps} timeout={350}>
                                                        <Paper
                                                            elevation={3}
                                                            style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                                            className='roundedcornes'
                                                        >
                                                            {row.versionTags
                                                                .map((isoDate, index) => (
                                                                    <Tooltip className="version-tooltip" title={`${DateTime.fromISO(isoDate, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')} V${index}`} placement="top">
                                                                        <MenuItem className='version-option' key={isoDate} onClick={event => onVersionClick(event, isoDate)} style={{ backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <p className='version-option-date'>{DateTime.fromISO(isoDate, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')}</p>
                                                                            <p className='version-option-name'>V{index}</p>
                                                                        </MenuItem>
                                                                    </Tooltip>
                                                                ))}
                                                        </Paper>
                                                    </Zoom>
                                                </ClickAwayListener>
                                            </div>
                                        )}
                                    </Popper>
                                </div>
                            </td>
                        )
                    } else if (tableHeader.key === TableHeaders.recordedMs) {
                        return <td className='table_row_cell' style={{ width: tableHeader.width }}>{formatDuration(row.recordedMs)}</td>
                    } else if (tableHeader.key === TableHeaders.createdAt) {
                        return <td className='table_row_cell' style={{ width: tableHeader.width }}>{convertAndFormatTimestamp(row.createdAt)}</td>
                    } else if (tableHeader.key === TableHeaders.updatedAt) {
                        return <td className='table_row_cell' style={{ width: tableHeader.width }}>{convertAndFormatTimestamp(row.updatedAt)}</td>
                    } else {
                        return <td className='table_row_cell' style={{ width: tableHeader.width }}>{row[tableHeader.key]}</td>
                    }
                })}
            </tr>
            {row.expanded && <ExpandedRow row={row} />}
        </>
    )
}

export default TableRow;