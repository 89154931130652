import axiosInstance from './axios';
import axios, { AxiosPromise, AxiosResponse } from 'axios';

import {
  API,
  CLIENT,
  RECORDINGS,
  SESSIONS,
  GROUPS,
  EDITOR_CONTENT,
  LABELS,
  COPY,
  SUMMARY,
  SHARES,
  USERS,
  CONTENT,
  TEXT_SEGMENTS,
  OPERATIONS,
  RECREATE,
  CANCEL,
  MERGE,
} from './endpoints';
import { ISortObject } from '../redux/store/IStore';
import { IGroup, ISession, ISessionEntry, ISessionEntryWithShared, ISessionWithShared, IShare, IUser } from '../components/Dashboard/IDashboard';
import { SessionSource, SessionState } from '../components/DashboardHeader/ISearch';
import { config } from '../config';
import { IConfigurationSend, IRecordingsArray } from '../components/Home/ISettings';
import { AccessMode, IPutSessionLock } from '../types';
import { IGetV30 } from './types';
import { IV3RecievedTranscript } from '../components/Libraries/ILibraries';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}`;

export interface IFilterOptions {
  name?: string;
  label?: string;
  'created-after'?: string;
  'created-before'?: string;
  'created-by-username'?: string; // admin only
  'is-discarded'?: boolean;
  source?: SessionSource;
  status?: SessionState;
}

export interface ILabelUpdateDTO {
  code: string;
  color: string;
  isDefault: boolean;
}

export interface IRecording {
  id: number,
  createdAt: string,
  duration: number,
  source: string,
  isDiscarded: boolean,
  transcriptionLanguage: string,
  transcriptionDomain: string,
  transcriptionModelVersion: string,
  transcriptionDoInterim: boolean,
  transcriptionDoPunctuation: boolean,
  transcriptionDoInterimPunctuation: boolean,
  transcriptionShowUnks: boolean,
  transcriptionDoDictation: boolean,
  transcriptionDoNormalisation: boolean,
  transcriptionDoDenormalisation: boolean,
  translationEnabled: boolean,
  translationLanguage: string
}

export const getEditorContentV30 = (
  sessionId: number,
  sessionLockKey: string | null,
  accessMode: AccessMode,
  tag: string | null,
): AxiosPromise<IGetV30> => {
  const url = backendUrl + `/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${CONTENT}?access-mode=${accessMode}${tag ? `&tag=${tag}` : ''}`;
  return axiosInstance.get(url, {
    headers: sessionLockKey ? {
      'Session-Lock-Key': sessionLockKey
    } : {}
  });
};

export const patchEditorContentV30 = (content: {rawContentState: any}, sessionId: number, editTicket: string, sessionLockKey: string) => {
  const url = backendUrl + `/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${CONTENT}?session-edit-ticket=${editTicket}`;
  return axiosInstance.patch(
    url,
    { content },
    {
      headers: {
        'Content-Type': 'application/json',
        'Session-Lock-Key': sessionLockKey
      },
    }
  );
};

export const deleteEditorContent = (editorContentId: number) => {
  const url = baseUrl + `/${EDITOR_CONTENT}/${editorContentId}`;
  return axiosInstance.delete(url);
};

export const getSessionTranscripts = (sessionId?: number): AxiosPromise<IV3RecievedTranscript[]> => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }
  const baseUrl = `${backendUrl}/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${TEXT_SEGMENTS}`;
  return axiosInstance.get(baseUrl);
};

export const getEditorContent = (sessionId: number) => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }
  const textUrl = `${backendUrl}/${API}/${CLIENT}/${SESSIONS}/${sessionId}/${CONTENT}`;
  return axiosInstance.get(textUrl);
};

export const getSessions = async (
  sliceCursor: string | undefined,
  sliceLength: number,
  filters: URLSearchParams,
  sortObject: ISortObject
): Promise<ISessionEntry[]> => {
  const sort = sortObject.sortBy ? `${sortObject.sortBy},${sortObject.sortDirection}` : null;
  if (sort) filters.append('sort', sort.toString());
  if (sliceCursor) {
    filters.append('slice-cursor', sliceCursor);
  }
  filters.append('slice-length', sliceLength.toString());
  const response = await axiosInstance.get(`${baseUrl}/${SESSIONS}`, {
    params: filters,
  });

  return response.data
};

export const getSessionsWithSharesForIds = async (ids: number[]) => {
  const sessions: ISession[] = []
  for (let idIndex = 0; idIndex < ids.length; idIndex++) {
    const id = ids[idIndex]
    const session = await getSession(id);
    sessions.push(session.data)
  }

  const combined: ISessionWithShared[] = []
  for (let i = 0; i < sessions.length; i++) {
    const session = sessions[i]
    session.labels = session.labels = session.labels.sort(function(a, b) {
      var textA = a.label.code.toUpperCase();
      var textB = b.label.code.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    if (session.sharedWithOthers) {
      const sharedWith = await getSessionSharedUsers(session.id)
      combined.push({
        ...session,
        shares: sharedWith.data
      })
    } else {
      combined.push({
        ...session,
        shares: []
      })
    }
  }

  return combined
}

export const getSessionsWithShares = async (
  sliceCursor: string | undefined,
  sliceLength: number,
  filters: URLSearchParams,
  sortObject: ISortObject
): Promise<{sessions: ISessionEntryWithShared[], last: boolean}> => {
  if (sortObject.sortBy && sortObject.sortDirection) {
    filters.append('sort-by', sortObject.sortBy);
    filters.append('sort-direction', sortObject.sortDirection.toUpperCase());
  }
  if (sliceCursor) {
    filters.append('slice-cursor', sliceCursor);
  }
  filters.append('slice-length', sliceLength.toString());
  const response = await axiosInstance.get(`${baseUrl}/${SESSIONS}`, {
    params: filters,
  }) as AxiosResponse<ISessionEntry[]>

  filters.delete('slice-length')
  filters.delete('slice-cursor')
  filters.delete('sort-by')
  filters.delete('sort-direction')

  const sessions = response.data as ISessionEntry[]
  const combined: ISessionEntryWithShared[] = []
  for (let i = 0; i < sessions.length; i++) {
    const session = sessions[i]
  

    if (session.content.isDiscarded) {
      session.content.status = SessionState.DELETED;
    }
    session.content.labels = session.content.labels = session.content.labels.sort(function(a, b) {
      var textA = a.label.code.toUpperCase();
      var textB = b.label.code.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
    if (session.content.sharedWithOthers) {
      const sharedWith = await getSessionSharedUsers(session.content.id)
      combined.push({
        ...session,
        shares: sharedWith.data,
        recordings: null,
        summary: null,
      })
    } else {
      combined.push({
        ...session,
        shares: [],
        recordings: null,
        summary: null,
      })
    }
  }
  return {
    sessions: combined,
    last: combined.length < sliceLength
  }
}

export const copySession = (sessionId: number) => {
  return axiosInstance.put(`${baseUrl}/${SESSIONS}/${sessionId}/${OPERATIONS}/${COPY}`, {
    lockSession: false
  });
};

/** START: Sharing with other users: */

export const removeShare = (shareId: number) => {
  return axiosInstance.delete(`${baseUrl}/${SESSIONS}/${SHARES}/${shareId}`);
};

//TODO: Filter by parameter on backend. Check swagger. Always prefix!
export const getAllUsers = () => {
  return axiosInstance.get<IUser[]>(`${backendUrl}/${API}/${USERS}`);
};

export const getSessionSharedUsers = (sessionId: number) => {
  return axiosInstance.get<IShare[]>(`${baseUrl}/${SESSIONS}/${sessionId}/${SHARES}`);
};

// Adds a single user to shared user pool of the specified session
const addSessionSharedUser = (sessionId: number, userId: number) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/${SHARES}`, {
    userId,
  });
};

export const addMultipleSharedUsers = (sessionId: number, userIds: number[]) => {
  return axios.all(userIds.map((id) => addSessionSharedUser(sessionId, id)));
};

/** END: Sharing with other users: */

export const getClientLabels = (code?: string) => {
  if (code) {
    return axiosInstance.get(`${baseUrl}/${LABELS}?code=%25${code}%25`);
  }
  return axiosInstance.get(`${baseUrl}/${LABELS}`);
};

export const getDefaultLabels = (id?: number) => {
  return axiosInstance.get(`${baseUrl}/${LABELS}?is-default=true${id ? `&created-by=${id}` : ""}`);
};

export const deleteClientLabel = (labelId: number) => {
  return axiosInstance.delete(`${baseUrl}/${LABELS}/${labelId}`);
};

export const patchClientLabel = (labelId: number, labelUpdateDTO: Partial<ILabelUpdateDTO>) => {
  return axiosInstance.patch(`${baseUrl}/${LABELS}/${labelId}`, { ...labelUpdateDTO });
};

export const registerNewClientLabel = (code: string, color: string, isDefault: boolean = false) => {
  return axiosInstance.post(`${baseUrl}/${LABELS}`, { code, color, isDefault });
};

export const addNewLabelToSession = (sessionId: number, labelId: number, isEnabled: boolean) => {
  return axiosInstance.post(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}`, { labelId, isEnabled });
};

export const updateSessionLabel = (sessionId: number, labelId: number, isEnabled: boolean) => {
  return axiosInstance.patch(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}/${labelId}`, {
    labelId,
    isEnabled,
  });
};

export const removeLabelFromSession = (sessionId: number, labelCode: number) => {
  return axiosInstance.delete(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}/${labelCode}`);
};

export const getSession = (sessionId: number): Promise<AxiosResponse<ISession>> => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}`);
};

export const deleteSession = (sessionId: number, isHardDelete?: boolean) => {
  return axiosInstance.delete(isHardDelete ? `${baseUrl}/${SESSIONS}/${sessionId}?hard=${true}` : `${baseUrl}/${SESSIONS}/${sessionId}`);
};

export const getSessionLabels = (sessionId: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}/${LABELS}`);
};

export const getSessionSummary = (sessionId: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${sessionId}/${SUMMARY}`);
};

export const getSessionDetails = (id: number) => {
  return axios.all([getSession(id), getSessionSummary(id)]);
};

export const getExpandedSessionDetails = (sessionId: number) => {
  return axios.all([getSessionSummary(sessionId), getSessionRecordings(sessionId)]);
}

export const patchSessionInfo = (info: { name?: string; notes?: string }, sessionId?: number, sessionLockKey?: string | null) => {
  if (!sessionId) {
    throw new Error('sessionId is missing');
  }

  if (Object.keys(info).length === 0) throw new Error('Provide at least 1 property to patch');

  const url = baseUrl + `/${SESSIONS}/${sessionId}`;

  let headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  }

  if (sessionLockKey) {
    headers = {...headers, 'Session-Lock-Key': sessionLockKey}
  }
  return axiosInstance.patch(url, info, { headers });
};

export const getSessionAudio = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${id}/audio.wav`);
};

export const getSessionRecordings = (id: number): Promise<AxiosResponse<IRecordingsArray>> => {
  return axiosInstance.get(`${baseUrl}/${SESSIONS}/${id}/${RECORDINGS}`);
};

export const deleteRecording = (recordingId: number) => {
  return axiosInstance.delete(`${baseUrl}/${RECORDINGS}/${recordingId}`);
};

export const getSessionRecordingsTest = (id: number) => {
  return axiosInstance.get(`${baseUrl}/${RECORDINGS}/${id}`);
};

export const getLabelSuggestions = (partialLabel: string) => {
  return axiosInstance.get(`${baseUrl}/${LABELS}?code=${partialLabel}%25`);
};

export const getAllUsersSuggestions = (username: string, limit: number) => {
  return axiosInstance.get<IUser[]>(`${backendUrl}/${API}/${USERS}?name=${username}&limit=${limit}`);
};

export const getSessionsNames = (filter: string, limit: number) => {
  return axiosInstance.get<string[]>(`${baseUrl}/${SESSIONS}/names?filter=${filter}&limit=${limit}`);
};

export const getGroupNames = (filter: string, limit: number) => {
  return axiosInstance.get<IGroup[]>(`${backendUrl}/${API}/${GROUPS}?name=${filter}&limit=${limit}`);
};

export const getAllDataAboutSessionAdmin = (id: number) => {
  return axios.all([
    getSession(id),
    getSessionSummary(id),
    getSessionRecordings(id),
    getSessionTranscripts(id),
  ]);
};


export const getNewSessionLock = (sessionId: number, sessionLockKey: string | null): Promise<AxiosResponse<IPutSessionLock>> => axiosInstance.put(`${baseUrl}/${SESSIONS}/${sessionId}/lock`, {}, sessionLockKey ? { headers: { "Session-Lock-Key": sessionLockKey } }: {});
export const unlockSession = (sessionId: number, sessionLockKey: string | null) => axiosInstance.delete(`${baseUrl}/${SESSIONS}/${sessionId}/lock`, sessionLockKey ? { headers: { "Session-Lock-Key": sessionLockKey } }: {});


export const recreateSession = (sessionId: number, pipeline: IConfigurationSend) => {
  return axiosInstance.put(`${baseUrl}/${SESSIONS}/${sessionId}/${OPERATIONS}/${RECREATE}?async=true`, {
    pipeline
  })
}

export const cancelSessionInProgress = (sessionId: number) => axiosInstance.put(`${baseUrl}/${SESSIONS}/${sessionId}/${OPERATIONS}/${CANCEL}`)
export const mergeSessions = (ids: number[]) => axiosInstance.put(`${baseUrl}/${SESSIONS}/${OPERATIONS}/${MERGE}`, {
  lockSession: false,
  sessionIds: ids
})

export const getMergedEditorContent = (ids: URLSearchParams) => axiosInstance.get(`${baseUrl}/${SESSIONS}/${CONTENT}?${ids}`)